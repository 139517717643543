@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "general/index";
@import "initialStyles";
@import "containers";
@import "sections";
@import "iconsValtec";
@import "cards";
@import "footer";
@import "offertSection";
@import "serviceSection";
@import "web-perufarma";
@import "ourLeaders";
@import "solutionSection";
@import "whyhire";
@import "callNow";